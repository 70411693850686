import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/pages/HomePage';
import PlayPage from '@/pages/PlayPage';
import StorePage from '@/pages/StorePage';
import HiscoresPage from '@/pages/HiscoresPage';
import VotePage from '@/pages/VotePage';
import LoginPage from '@/pages/Admin/LoginPage';
import TransactionHistoryPage from '@/pages/Admin/TransactionHistoryPage';
import ChargebackLogsPage from '@/pages/Admin/ChargebackLogsPage';
import StoreRedirect from '@/pages/StoreRedirect';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    component: () => import('@/layouts/Main'),
    children: [
      {
        path: '/',
        name: 'home',
        component: HomePage,
      },
      {
        path: '/play',
        name: 'play',
        component: PlayPage,
      },
      {
        path: '/hiscores',
        name: 'hiscores',
        component: HiscoresPage,
      },
      {
        path: '/store-new',
        name: 'store',
        component: StorePage,
      },
      {
        path: '/vote',
        name: 'vote',
        component: VotePage,
      },
      {
        path: '/store',
        name: 'store',
        component: StoreRedirect,
      },
      {
        path: '/update-log',
        name: 'update-log',
        redirect: () => {
          window.location.href =
            'https://community.simplicityps.org/index.php?/topic/11036-thread';
          return;
        },
      },
      {
        path: '/community/index.php',
        name: 'community',
        redirect: () => {
          window.location.href = `https://community.simplicityps.org/index.php${window.location.search}`;
          return;
        },
      },
      {
        path: '/community/',
        name: 'community',
        redirect: () => {
          window.location.href = `https://community.simplicityps.org/index.php${window.location.search}`;
          return;
        },
      },
    ],
  },
  {
    path: '',
    component: () => import('@/layouts/StoreAdmin'),
    children: [
      {
        path: '/store/login',
        name: 'store-login',
        component: LoginPage,
      },
      {
        path: '/store/transaction-history',
        name: 'transaction-history',
        component: TransactionHistoryPage,
      },
      {
        path: '/store/chargeback-logs',
        name: 'chargeback-logs',
        component: ChargebackLogsPage,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  return next();
});

export default router;
